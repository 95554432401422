@font-face {
  font-family: "Suisse Int'l";
  src: url('fonts/SuisseIntl-Bold.woff2') format('woff2'),
  url('fonts/SuisseIntl-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Suisse Int'l";
  src: url('fonts/SuisseIntl-Medium.woff2') format('woff2'),
  url('fonts/SuisseIntl-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Suisse Int'l";
  src: url('fonts/SuisseIntl-Regular.woff2') format('woff2'),
  url('fonts/SuisseIntl-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
html,
body {
  width: 100%;
  min-height: 100%;
  margin: 0;
  padding: 0;
  font-family: "Suisse Int'l" !important;
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
}
a {
  text-decoration: none;
}
.text-sm {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #585f73;
}
@media (min-width: 1400px) {
  .container {
    max-width: 1200px;
    padding: 0;
  }
}
#intro {
  padding-top: 48px;
  padding-bottom: 48px;
  background: linear-gradient(
    180deg,
    rgba(242, 243, 246, 0.29) 0%,
    #ffffff 100%
  );
}
#intro-row {
  padding-top: 77px;
  padding-bottom: 136px;
}

.title strong {
  display: block;
  color: #2999ff;
  font-size: 24px;
  line-height: 34px;
}

.title h1 {
  color: #170f35;
  font-size: 48px;
  line-height: 60px;
  margin-top: 0;
  margin-bottom: 16px;
  font-weight: 700;
}

.title p {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: #585f73;
  margin-bottom: 48px;
}

.cta {
  border-radius: 54px;
  border: 1px solid;
  text-align: center;
  display: inline-block;
}

.cta a {
  font-weight: 600;
  font-size: 15px;
  display: block;
  padding: 12px 24px;
}

.cta.cta-blue {
  background-color: #2999ff;
  border-color: #2999ff;
}
.cta.cta-blue a {
  color: #fff;
}
.cta.cta-white {
  background-color: #fff;
  border-color: #170f35;
  margin-left: 24px;
}
.cta.cta-white a {
  color: #170f35;
}
.cta.cta-dark {
  border: 1px solid #ffffff;
}
.cta.cta-dark a {
  color: #fff;
}
.cta.cta-light {
  background-color: #fff;
  border-color: #fff;
}
.cta.cta-light a {
  color: #170f35;
}
.statistics h2 {
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 48px;
  color: #170f35;
}

.statistics .col-inner {
  background: #ffffff;
  box-shadow: 0px 11px 22px rgba(70, 70, 70, 0.09);
  border-radius: 24px;
  padding: 32px 24px;
  height: 100%;
  margin-bottom: 18px;
}
.statistics .col-inner .percentage {
  font-size: 40px;
  line-height: 52px;
  text-align: center;
  color: #2999ff;
  font-weight: 600;
}
.statistics .col-inner p {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #585f73;
  margin: 0;
}
#features {
  padding: 80px 0;
}

#features h3 {
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;
  color: #170f35;
  margin-bottom: 80px;
}
#features.sms-platform-features h3 {
  margin-bottom: 16px;
}
#features .subtitle {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #585f73;
  max-width: 690px;
  margin: 0 auto 64px auto;
}
#features .col-inner {
  display: flex;
  align-items: flex-start;
}
#features .col-inner img {
  margin-right: 16px;
}
#features .col-inner h4 {
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #170f35;
  margin-top: 0;
  margin-bottom: 8px;
}

#features .col-inner p {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #585f73;
}
#features.sms-platform-features .col-inner {
  margin-bottom: 48px;
}
#features.sms-platform-features .col-inner p {
  max-width: 414px;
}
#main-features {
  padding: 80px 0 100px 0;
}
#main-features h2 {
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;
  text-align: center;
  color: #170f35;
  margin-bottom: 64px;
}
#main-features p {
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #170f35;
  margin-top: 16px;
}
#trending-use-case {
  padding: 80px 0;
  background-color: #fafafb;
}
#trending-use-case h2 {
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;
  color: #170f35;
  margin-bottom: 16px;
}
#trending-use-case h3.small-title {
  font-size: 24px;
  line-height: 34px;
}
#trending-use-case .subtitle {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #585f73;
  max-width: 792px;
  margin: 0 auto 64px auto;
}
#trending-use-case h3,
#platform-features h3 {
  font-size: 32px;
  line-height: 44px;
  color: #170f35;
  margin-bottom: 64px;
  font-weight: 700;
}
#trending-use-case ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
#trending-use-case ul li {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #585f73;
  display: flex;
  align-items: center;
}
#trending-use-case ul li:before {
  content: '';
  width: 6px;
  height: 6px;
  background-color: #585f73;
  margin-right: 8px;
  border-radius: 50%;
}
.trending-inner .line {
  width: 32px;
  height: 3px;
  background: #2999ff;
  margin-bottom: 12px;
}
.trending-inner h4 {
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
  color: #170f35;
  margin-bottom: 16px;
}
.trending-inner p {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #585f73;
  margin-bottom: 24px;
  max-width: 360px;
}
#experience.viber-experience [class*='col-']:not(.col-inner) {
  margin-bottom: 30px;
}
#experience.viber-experience .col-inner {
  min-height: 319px;
}
#features .separator {
  margin-bottom: 66px;
}
#sms-main-features h3 {
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;
  color: #170f35;
  margin-bottom: 40px;
}
#sms-main-features h3 span {
  color: #2999ff;
}
#sms-main-features .inner {
  border: 2px solid #dfe9fc;
  border-radius: 24px;
  padding: 40px 24px;
  height: 100%;
}
#sms-main-features .inner h5 {
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
  color: #2999ff;
  margin-bottom: 16px;
}
#sms-main-features [class*='col-'] {
  margin-bottom: 30px;
}
#sms-main-features .inner p {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #585f73;
}
#available-formats {
  background: linear-gradient(
    180deg,
    #ffffff 0%,
    rgba(242, 243, 246, 0.29) 100%
  );
}
#available-formats .inner {
  padding-top: 120px;
  background: url('../../public/imgs/whatsapp-formats.png') no-repeat right bottom;
  background-size: 486px 597px;
  padding-bottom: 140px;
}
#available-formats .inner.viber {
  background: url('../../public/imgs/viber-formats.png') no-repeat right bottom;
}
#available-formats .inner h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;
  color: #170f35;
  margin-bottom: 190px;
}

#available-formats .inner h4 {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
  color: #170f35;
}

#available-formats .inner p {
  margin-top: 16px;
  margin-bottom: 0;
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  color: #585f73;
}
#available-formats .inner .inner-content {
  max-width: 580px;
}
#experience {
  padding-top: 100px;
  padding-bottom: 80px;
}
#experience h3 {
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;
  margin-bottom: 48px;
  margin-top: 0;
}
#experience .title-sm {
  font-weight: 500;
  font-size: 24px;
  line-height: 34px;
  color: #170f35;
}

#experience .col-inner {
  background: #fafafb;
  border-radius: 14px;
  padding: 38px 45px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  min-height: 360px;
}

#experience .col-inner h5 {
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #170f35;
}

#experience .col-inner p {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #585f73;
}
.col-inner .msg {
  background: #2999ff;
  box-shadow: 0px 12px 16px rgba(41, 153, 255, 0.15);
  border-radius: 16px 16px 16px 0px;
  padding: 18px;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #fff;
}
#experience .col-inner .msg-block .msg {
  max-width: 282px;
  margin-right: auto;
}
#experience .col-inner .msg.msg-white {
  background: #ffffff;
  box-shadow: 0px 11px 22px rgba(70, 70, 70, 0.09);
  border-radius: 28px 28px 0px 28px;
  color: #170f35;
  margin-bottom: 17px;
  max-width: 258px;
  margin-left: auto !important;
  margin-right: 0 !important;
}

#experience .col-inner .msg.msg-small {
  max-width: 282px;
}
#experience .separator {
  height: 48px;
}
#pricing h3 {
  font-size: 32px;
  line-height: 44px;
  text-align: center;
  color: #170f35;
  margin-bottom: 8px;
  font-weight: 700;
}
#pricing .inner-container {
  margin-top: 50px;
  max-width: 960px;
}
#pricing {
  padding-bottom: 100px;
}

#pricing .inner-container img {
  margin-bottom: 20px;
}
#pricing .inner-container h6 {
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #170f35;
}
#registration-process {
  padding: 80px 0 140px 0;
  background-color: #0a0d1e;
}
#registration-process h4 {
  font-weight: 700;
  font-size: 32px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 64px;
  font-weight: bold;
}
#registration-process .number {
  font-size: 32px;
  line-height: 44px;
  color: #2999ff;
  margin-bottom: 8px;
  font-weight: 700;
}
#registration-process .title {
  font-size: 18px;
  line-height: 24px;
  color: #ffffff;
  margin-bottom: 8px;
  font-weight: 700;
}
#registration-process .text {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.6);
  max-width: 326px;
}
#registration-process .final-text {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 24px;
  margin-top: 72px;
}
#most-used {
  padding-bottom: 100px;
}
#most-used .most-used-inner {
  background: linear-gradient(180deg, #7360f2 0%, #5f49f0 100%);
  border-radius: 32px;
  padding: 64px;
  position: relative;
  z-index: 10;
}
#most-used .most-used-inner:after {
  content: '';
  position: absolute;
  width: 1016px;
  height: 125px;
  bottom: -10px;
  background: rgba(115, 96, 242, 0.4);
  filter: blur(89px);
  border-radius: 16px;
  z-index: -1;
}

.most-used-inner h3 {
  color: #fff;
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;
  margin-bottom: 40px;
}
.most-used-inner .country {
  height: 48px;
  background: #ffffff;
  box-shadow: 0px 11px 22px rgba(70, 70, 70, 0.09);
  border-radius: 31px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: 24px;
  color: #170f35;
  margin-bottom: 24px;
}
.most-used-inner .country img {
  margin-right: 12px;
}
#brands h3 {
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;
  margin-bottom: 40px;
}
#brands .logos {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
}
#brands .text-sm {
  max-width: 790px;
  margin: 0 auto;
}
#brands {
  padding-bottom: 120px;
}
#talk-to-expert {
  padding-bottom: 140px;
}
#talk-to-expert .form-container {
  background: linear-gradient(0deg, #ffffff, #ffffff), #f8f8f8;
  border: 1px solid #eeeeee;
  box-shadow: 0px 10px 36px rgba(70, 70, 70, 0.06);
  border-radius: 16px;
  padding: 64px 0;
  margin-top: 80px;
}
#talk-to-expert .form-container h3 {
  font-size: 32px;
  line-height: 44px;
  text-align: center;
  color: #170f35;
  margin-bottom: 16px;
  font-weight: 700;
}
#talk-to-expert .form-container h3 span {
  color: #2999ff;
}
#talk-to-expert .form-container .subtitle {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #585f73;
  max-width: 632px;
  margin: 0 auto 44px auto;
}
#talk-to-expert .form-container form {
  max-width: 792px;
  margin: 0 auto !important;
}
#talk-to-expert .form-container form label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height, or 129% */

  color: #585f73;
}
#talk-to-expert .form-container form input:not(input[type='submit']),
#talk-to-expert .form-container form select {
  display: block;
  width: 100%;
  height: 46px;
  line-height: 46px;
  padding-left: 24px;
  margin-bottom: 20px;
  border: 1px solid #e6e6e6;
  border-radius: 8px;
  font-size: 13px;
  line-height: 22px;
  color: #585f73;
}
#talk-to-expert .form-container form select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url('../../public/imgs/arrow-down.svg') no-repeat right 15px center;
}
#talk-to-expert .form-container form input::placeholder {
  color: #585f73;
  opacity: 0.6;
}
#talk-to-expert .form-container form input:focus {
  border-color: #2999ff !important;
}
#talk-to-expert .form-container .form-info {
  font-weight: 500;
  font-size: 13px;
  line-height: 24px;
  color: #585f73;
  margin-top: 32px;
  max-width: 792px;
  margin-left: auto;
  margin-right: auto;
}
form input[type='submit'] {
  background: #2999ff;
  border-radius: 8px;
  width: 214px;
  height: 42px;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  color: #fff;
  border: none;
  box-shadow: none;
  margin-top: 30px;
}
#sms-auth {
  padding: 80px 0;
}
#sms-auth h3 {
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;
  color: #170f35;
  margin-bottom: 16px;
}
#sms-auth p {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #585f73;
}
#sms-auth .img-wrapper {
  background: #fafafb;
  border-radius: 24px;
  padding: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
#sms-auth .inner {
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 586px;
}
#industries {
  background: linear-gradient(
    180deg,
    #ffffff 0%,
    rgba(242, 243, 246, 0.29) 100%
  );
  padding: 60px 0;
}
#industries h3 {
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;
  color: #170f35;
  margin-bottom: 8px;
  text-align: center;
}
#industries h3 span {
  color: #2999ff;
}
#industries p.subtitle {
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  text-align: center;
  color: #585f73;
  max-width: 792px;
  margin: 0 auto 100px auto;
}
.industries-inner h4 {
  font-size: 24px;
  line-height: 34px;
  color: #170f35;
  margin-bottom: 16px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: 700;
}
.industries-inner p {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #585f73;
  text-align: left;
  max-width: 564px;
}
.industry {
  background: #ffffff;
  box-shadow: 0px 11px 22px rgba(70, 70, 70, 0.09);
  border-radius: 62px;
  margin-right: 22px;
  display: inline-flex;
  align-items: center;
  padding: 8px 20px 8px 8px;
  margin-bottom: 22px;
}
.industry:nth-child(3n + 3) {
  margin-right: 0;
}
.industry span {
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #170f35;
  margin-left: 12px;
}
#message-use-case h3 {
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;
  color: #170f35;
  margin-bottom: 75px;
}
#message-use-case {
  padding-top: 100px;
}
#message-use-case h3 span {
  color: #2999ff;
}
#message-use-case h5 {
  margin-top: 24px;
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #170f35;
}
#message-use-case p {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #585f73;
}
#message-use-case .msg-wrapper {
  height: 125px;
  display: flex;
  align-items: center;
}
#message-use-case .col-inner {
  max-width: 282px;
  margin: 0 auto 64px auto;
}
#message-use-case > .container {
  max-width: 1128px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 80px;
}
#sms-api-last {
  background: url('../../public/imgs/api-request.png') no-repeat right center #fafafb;
  padding: 112px 0 170px 0;
  margin-top: 80px;
  background-size: 50%;
}
#sms-api-last .content {
  max-width: 384px;
}
#sms-api-last .content h4 {
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;
  color: #170f35;
  margin-bottom: 16px;
}
#sms-api-last .content span {
  color: #2999ff;
}
#sms-api-last .content p {
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  color: #585f73;
  margin-bottom: 48px;
}
#sms-auth .img-wrapper img {
  max-width: 100%;
}
#trending-use-case-reminder h3 {
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;
  text-align: center;
  color: #170f35;
  margin-bottom: 64px;
}
#trending-use-case-reminder {
  padding-bottom: 100px;
  padding-top: 80px;
}
#trending-use-case-reminder h4 {
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #170f35;
  margin-bottom: 16px;
}
#trending-use-case-reminder .col-inner {
  max-width: 282px;
  margin: 0 auto 48px auto;
}
#sms-reminder-use-case {
  background: #fafafb;
  padding: 80px 0;
}
#sms-reminder-use-case h3 {
  font-size: 32px;
  line-height: 44px;
  color: #170f35;
  margin-bottom: 48px;
  font-weight: 700;
}
#sms-reminder-use-case h3 span {
  color: #2999ff;
}
#sms-reminder-use-case h4 {
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #170f35;
}
#sms-reminder-use-case p {
  font-size: 16px;
  line-height: 24px;
  color: #585f73;
  margin-bottom: 60px;
}
#sms-reminder-use-case .inner {
  max-width: 383px;
}
#sms-reminder-use-case .inner.inner-right-end {
  margin-left: auto;
}
#sms-reminder-use-case > .sms-reminder-container {
  max-width: 996px;
  margin: 0 auto;
  padding: 0 15px;
}
#sms-reminder-use-case .img-wrapper {
  height: 100%;
  display: flex;
  align-items: center;
}
#sms-reminder-use-case img {
  max-width: 100%;
  width: 100% !important;
}
#needs {
  padding: 80px 0 100px 0;
}
#needs h3 {
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;
  text-align: center;
  color: #170f35;
  margin-bottom: 64px;
}
#needs .col-inner {
  display: flex;
  align-items: center;
  justify-content: center;
}
#needs .container {
  max-width: 996px;
  margin: 0 auto;
}
#needs .col-inner h5 {
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #170f35;
  margin-left: 16px;
}
#sms-reminder-industries {
  padding: 80px 0 120px 0;
  background: linear-gradient(
    180deg,
    rgba(242, 243, 246, 0.29) 0%,
    #ffffff 100%
  );
}
#sms-reminder-industries h3 {
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;
  text-align: center;
  color: #170f35;
  margin-bottom: 48px;
}
#sms-reminder-industries .industries {
  max-width: 1030px;
  margin: 0 auto 100px auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#sms-reminder-industries .industries .industry {
  margin: 0;
}
.statistics .title {
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
  color: #2999ff;
  margin-bottom: 8px;
}
#registration-process .inner {
  display: flex;
  color: #ffffff;
  justify-content: center;
}
#registration-process .inner p {
  color: #fff;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  margin-right: 48px;
  margin-bottom: 0;
  line-height: 48px;
}
#registration-process .inner .cta-dark {
  margin-left: 18px;
}
.sms-marketing-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 100px 30px;
}
.sms-marketing-container h3 {
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;
  color: #170f35;
  margin-bottom: 10px;
}
.sms-marketing-container p {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 0;
  color: #585f73;
}
.sms-marketing-container p strong {
  color: #170f35;
}
.sms-api-platform-white {
  max-width: 792px !important;
}
#sms-marketing-pricing p:last-child {
  margin-bottom: 0 !important;
}
.sms-marketing-container .content {
  max-width: 564px;
}
.sms-marketing-trending {
  background: linear-gradient(
    180deg,
    rgba(242, 243, 246, 0.29) 0%,
    #ffffff 100%
  ) !important;
}
.sms-api-platform-steps .title {
  margin-bottom: 24px !important;
}
.sms-api-platform-steps .cta {
  margin-top: 40px;
}
.sms-api-platform-steps .cta a {
  padding-left: 50px;
  padding-right: 50px;
}
.sms-marketing-trending-use-case .col-inner {
  max-width: 308px !important;
}
.sms-marketing-trending-use-case .col-inner .msg {
  max-width: 282px;
}
#platform-features {
  padding: 80px 0;
}
#sms-marketing-pricing {
  padding: 80px 0;
  background-color: #2999ff;
}
#sms-marketing-pricing h3 {
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;
  color: #ffffff;
}
#sms-marketing-pricing p {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  max-width: 588px;
  margin: 17px auto 40px auto;
}
#sms-marketing-steps {
  padding: 80px 0 100px 0;
}
#sms-marketing-steps h3 {
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;
  color: #170f35;
  margin-bottom: 64px;
}
#sms-marketing-steps .steps-inner {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: flex-start;
}
.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  flex-basis: 0;
}
.step img {
  width: 88px;
  height: 88px;
  margin: 0 auto;
}
.step h5 {
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #170f35;
  margin-top: 16px;
  margin-bottom: 0;
}
#sms-marketing-usage {
  padding-bottom: 100px;
}
#sms-marketing-usage h3 {
  margin-bottom: 16px;
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;
  text-align: center;
  color: #170f35;
}
#sms-marketing-usage p {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #585f73;
  margin-bottom: 32px;
  max-width: 840px;
  margin: 0 auto;
}
#sms-marketing-usage img {
  max-width: 862px;
  width: 100%;
  margin-top: 40px;
}
#choosing-sms-alert {
  padding: 80px 0;
}
#choosing-sms-alert h3 {
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;
  text-align: center;
  color: #170f35;
  margin-bottom: 64px;
}
#choosing-sms-alert h3 span {
  color: #2999ff;
}
.sms-alert-trending {
  background-color: #fafafb;
}
#choosing-sms-alert .inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 996px;
  margin: 0 auto;
}
#choosing-sms-alert .inner .item {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-grow: 1;
  flex-basis: 0;
  justify-content: center;
}

#choosing-sms-alert .inner .item h5 {
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
  color: #170f35;
  margin-left: 16px;
}
#choosing-sms-alert p {
  max-width: 841px;
  margin: 40px auto;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #585f73;
}
#choosing-sms-alert p a {
  color: #2999ff;
}
#choosing-sms-alert .cta a {
  padding-left: 42px;
  padding-right: 42px;
}
#sms-api-platform-last {
  padding: 80px 0;
}
#sms-api-platform-last h5 {
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
  color: #170f35;
  margin-bottom: 16px;
}
#sms-api-platform-last .container {
  max-width: 978px;
}
#sms-api-platform-last .inner {
  display: flex;
  justify-content: space-between;
}
#sms-api-platform-last p {
  margin-bottom: 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #585f73;
  max-width: 588px;
}
.sms-api-platform-apis {
  background-color: #fff;
}
.sms-api-platform-apis p {
  max-width: 258px;
}
@media screen and (max-width: 1201px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    width: 100%;
    max-width: 1100px;
  }
  .statistics .col-inner .percentage {
    font-size: 32px;
  }
  .statistics .col-inner {
    padding: 24px 16px;
  }
  #available-formats .inner {
    background-size: 400px auto;
  }
  #available-formats .inner .inner-content {
    max-width: 480px;
  }
  #available-formats .inner h3 {
    margin-bottom: 100px;
  }
  #experience .col-inner {
    padding: 30px;
  }
  #sms-reminder-industries {
    padding-left: 15px;
    padding-right: 15px;
  }
  #sms-reminder-use-case img {
    height: auto !important;
  }
}
@media only screen and (max-width: 1023px) {
  #trending-use-case.sms-alert-trending {
    padding: 60px 0;
  }
  #choosing-sms-alert .inner .item {
    flex-direction: column;
    justify-content: center;
    width: 200px;
    flex-basis: 200px;
  }
  #choosing-sms-alert .inner {
    flex-wrap: wrap;
    justify-content: center;
  }
  #choosing-sms-alert .inner .item h5 {
    margin-left: 0;
    margin-top: 18px;
    margin-bottom: 30px;
  }
  .sms-marketing-container img {
    max-width: 100%;
    height: auto;
  }
  .sms-marketing-img {
    margin-left: -50px;
  }
  .sms-marketing-container {
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 60px 15px;
  }
  .sms-marketing-container p {
    margin-bottom: 30px;
  }

  #main-features p {
    font-size: 16px;
  }
  #intro .img-wrapper img {
    max-width: 100%;
    height: auto;
  }
  .title h1 {
    font-size: 32px;
    line-height: 40px;
  }
  .container,
  .container-md,
  .container-sm {
    max-width: 990px;
    width: 100%;
  }
  #intro-row .col-lg-6 {
    width: 100%;
  }
  .statistics {
    justify-content: center;
  }
  .title p {
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
  }
  #experience .col-inner {
    max-width: 500px;
    margin: 0 auto;
  }
  #intro-row,
  #intro-row .text-end {
    text-align: center !important;
  }
  #intro-row .img-wrapper {
    margin-top: 30px;
  }
  #intro-row {
    padding-bottom: 90px;
  }
  .trending-inner {
    text-align: center;
  }
  .trending-inner p,
  .trending-inner .line {
    margin-left: auto;
    margin-right: auto;
  }
  .step {
    margin: 30px;
  }
  #features .col-inner {
    justify-content: center;
  }
  .form-container form {
    padding-left: 15px;
    padding-right: 15px;
  }
  #talk-to-expert .form-container .form-info {
    padding: 0 15px;
    text-align: center;
  }
  #talk-to-expert {
    padding: 0 15px;
  }
  #talk-to-expert .form-container {
    padding-left: 15px;
    padding-right: 15px;
  }
  .statistics [class*='col-'],
  #experience [class*='col-']:not(.col-inner),
  #pricing [class*='col-'],
  #main-features [class*='col'],
  .trending-inner [class*='col-'] {
    margin-bottom: 30px;
  }
  #features .separator {
    display: none;
  }
  #features .col-inner p {
    margin-bottom: 30px;
  }
  #available-formats .inner {
    padding-top: 0;
    padding-bottom: 90px;
    text-align: center;
    margin: 0 auto;
    background-image: none !important;
  }
  #available-formats .inner .inner-content {
    margin: 0 auto;
  }
  #available-formats .inner .inner-content img {
    max-width: 100%;
    margin: 48px 0;
  }
  #available-formats .inner h3 {
    margin-bottom: 0;
  }
  h2,
  h3 {
    font-size: 28px !important;
  }
  #experience .col-inner,
  #experience.viber-experience .col-inner {
    display: block;
    min-height: unset;
    height: auto;
  }
  #experience .col-inner p {
    margin-bottom: 30px;
  }
  #experience .col-inner img {
    margin: 20px auto;
    display: block;
  }
  #registration-process [class*='col-'] {
    text-align: center;
    margin-bottom: 30px;
  }
  #registration-process .text {
    margin: 0 auto;
  }
  #most-used .most-used-inner {
    padding: 30px;
  }
  #brands .logos {
    flex-wrap: wrap;
    justify-content: center;
  }
  #brands .logos > div {
    margin: 0 15px 30px 15px;
  }
  .cta.cta-white {
    margin-top: 20px;
  }
  .industry:nth-child(3n + 3) {
    margin-right: 24px;
  }
  .industries-inner {
    text-align: center;
  }
  .industries-inner p {
    margin: 0 auto 30px auto;
    text-align: center;
  }
  .industries {
    text-align: center;
  }
  #sms-main-features h3 {
    text-align: center;
  }
  #sms-api-last {
    background-image: none;
  }
  #sms-api-last .content {
    margin: 0 auto;
    text-align: center;
  }
  #needs .col-inner {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 30px;
  }
  #needs .col-inner h5 {
    max-width: 200px;
    margin: 10px auto 0 auto;
  }
  #needs .col-inner h5 br {
    display: none;
  }
  #sms-reminder-industries .industries {
    flex-wrap: wrap;
    justify-content: center;
  }
  #sms-reminder-industries .industries .industry {
    margin: 0 12px 24px;
  }
  #registration-process .inner {
    flex-direction: column;
    text-align: center;
    justify-content: center;
  }
  #registration-process .inner p {
    margin-bottom: 15px;
    margin-right: 0;
  }
  #registration-process .inner .cta {
    display: inline-block;
    max-width: 200px;
    margin: 0 auto;
  }
  #registration-process .inner .cta-dark {
    margin-top: 15px;
  }
  #trending-use-case-reminder {
    padding-bottom: 0;
  }
  #sms-api-platform-last .inner {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  #sms-api-platform-last p {
    margin-bottom: 24px;
  }
}
@media screen and (max-width: 769px) {
  #logo {
    text-align: center;
  }
  #logo img {
    max-width: 120px;
  }
  #sms-reminder-industries {
    padding-bottom: 45px;
  }
  #needs {
    padding-bottom: 45px;
  }
  #sms-reminder-use-case {
    padding: 45px 0;
  }
  #sms-reminder-use-case p {
    margin-bottom: 39px;
  }
  #sms-reminder-use-case .inner.inner-right-end {
    margin-left: unset;
  }
  #sms-reminder-use-case .inner {
    margin: 0 auto 30px auto !important;
  }
  #sms-auth .inner {
    text-align: center;
  }
  #sms-auth .img-wrapper {
    padding: 10px;
    margin: 30px auto 0 auto;
    display: table;
  }
  #sms-auth .inner {
    max-width: unset;
  }
  #sms-auth {
    padding-top: 40px;
  }
  #trending-use-case ul li {
    justify-content: center;
  }
}
